.project-details {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
}

.project-title {
    font-size: 2.5em;
    margin-bottom: 15px;
    text-align: center;
    color: #5d54e7;
}

.main-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.main-image-container img{
    width: 100%;
}

.main-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-description {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 15px 0;
    text-align: center;
    color: #ffffff;
}

.gallery {
    margin-top: 30px;
    text-align: center;
}

.gallery h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #333;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    padding: 0 15px;
}

.gallery-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.gallery-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.add-images {
    margin-top: 40px;
    text-align: center;
    padding-top: 15px;
    border-top: 1px solid #ddd;
}

.add-images h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.upload-input {
    margin-top: 10px;
    padding: 8px;
}

.upload-button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1em;
    background-color: #5cb85c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #4cae4c;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #5d54e7;
}

.next-project-button, .previous-project-button {
    display: inline-block;
    padding: 9px 15px;
    background: #5d54e7;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
    border: none;
}

.navigation-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 4%;
}

.back-button-containerdev {
    margin: 20px 0;
    text-align: center;
}

.back-buttondev {
    display: inline-block;
    padding: 10px 20px;
    background-color: #5d54e7;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.back-buttondev:hover {
    background-color: #4d41f7;
}


@media (max-width: 600px) {
    .next-project-button, .previous-project-button {
        font-size: 12px;
    }

    .project-title{
        font-size: 25px;
    }
}

@media (max-width: 400px) {
    .next-project-button, .previous-project-button {
        margin-top: 10px;
    }

    .navigation-buttons {
        flex-direction: column;
    }

    .project-title{
        font-size: 18px;
    }
}