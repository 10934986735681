@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1d3557, #130e24);
  color: #f1faee;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.not-found-content {
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

.not-found-title {
  font-size: 8rem;
  font-weight: 600;
  color: #6c63ff;
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  animation: bounce 1.5s infinite alternate;
}

.not-found-message {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #f1faee;
  animation: slideUp 1.2s ease-out;
}

.not-found-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #1d3557;
  background-color: #f1faee;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  transition: transform 0.3s, background-color 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.not-found-button:hover {
  background-color: #a8dadc;
  transform: translateY(-5px);
}

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
