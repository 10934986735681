/* Затемненный фон для попапа */

/* Класс для блокировки прокрутки */
.no-scroll {
  overflow: hidden;
}

.request-btn {
  position: relative;
  overflow: visible;
}

/* Стиль для шапки */
.request-btn .christmas-hat {
  position: absolute;
  top: -27px;
  right: -14px;
  transform: rotate(15deg);
  width: 40px;
  height: auto;
  transition: transform 0.3s ease, top 0.3s ease;
  pointer-events: none; /* Чтобы шапка не блокировала клики по кнопке */
}

/* Анимация при наведении */
.request-btn:hover .christmas-hat {
  transform: rotate(25deg);
  top: -30px;
  right: -15px;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 29, 37, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}

/* Контейнер для попапа */
.popup-container {
  position: relative;
  width: 80%;
  max-width: 90%;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.popup-contents {
  display: flex;
  width: 100%;
}

.popup-contents h2 {
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: 200;
}

.left-half.NameM{
  font-size: 25px;
}

.NameM {
  margin-top: 20px;
  text-align: center;
  color: #4d46b8;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.NameN {
  margin-top: 20px;
  text-align: center;
  color: #30B100;
  font-size: 20px;
  display: flex;
  justify-content: center;
}


/* Половинки попапа */
.popup-half {
  width: 50%;
  padding: 70px 7% 70px 7%;
  box-sizing: border-box;
  color: #fff;
}

/* Левая часть с уникальным цветом */
.left-half {
  background-color: #1c1d25;
  opacity: 0;
  transform: translateY(100%);
  animation: slideUp 0.5s forwards;
}

/* Правая часть с другим цветом */
.right-half {
  background-color: #ffffff;
  opacity: 0;
  transform: translateY(-100%);
  animation: slideDown 0.5s forwards;
}


.right-half h2{
  color: #1c1d25;
}

/* Стили для формы */
.right-half form {
  display: flex;
  flex-direction: column;
}

.right-half input {
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.sub {
  padding: 10px;
  background: #5d54e7;
  color: #ffffff;
  box-shadow: 0 0 10px #5d54e7;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}



/* Кнопка закрытия попапа */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 50px;
  cursor: pointer;
  color: #1c1d25;
  z-index: 999;
}

.close-btn:hover {
  color: #5d54e7;
}

/* Анимация для левой части */
@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Анимация для правой части */
@keyframes slideDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Стили для формы */
.right-half form {
  display: flex;
  flex-direction: column;
}

.right-half input {
  margin-bottom: 20px;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid hsl(0, 0%, 80%);
  background: none;
  color: #1c1d25;
  font-size: 16px;
  outline: none;
  transition: border-bottom 0.3s ease;
}

/* Анимация градиента для нижнего бордера при наведении */
.right-half input:focus,
.right-half input:hover {
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, #ff7eb3, #6c63ff);
  border-image-slice: 1;
}

/* Кнопка отправки */
.right-half button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.required {
  color: #1c1d25
}

/* Стили для описания О нас с увеличенным межстрочным интервалом */
.left-half p {
  font-size: 15px;
  text-align: justify;
}
/* Стили для инпутов с анимацией нижнего бордера */
.right-half input {
  margin-bottom: 20px;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #5d54e7;
  background: none;
  color: #1c1d25;
  font-size: 16px;
  outline: none;
  position: relative;
}

/* Псевдоэлемент для анимации нижнего бордера */
.right-half input::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #ff7eb3, #6c63ff);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

/* Активация анимации при фокусе или наведении */
.right-half input:focus::after,
.right-half input:hover::after {
  transform: scaleX(1);
}

.toggle-view-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Расстояние между текстом и стрелкой */
  padding: 10px 20px;
  color: #1c1d25;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.toggle-view-btn.about{
  margin: 0 auto;
  margin-top: 5%;
  background: #cccccc00;
  color: #ffffff;
}

.toggle-view-btn:hover {
  transform: scale(1.05); /* Легкое увеличение */
}

.toggle-view-btn:active {
  transform: scale(0.95); /* Легкое уменьшение при нажатии */
}

.toggle-view-btn i {
  font-size: 18px; /* Размер стрелки */
}

.politic {
  color: #1c1d25;
  font-size: 12px;
  padding-top: 10px;
}

.politic span {
  color: #5d54e7;
}
  
@media (max-width: 1000px) {
  .popup-contents {
    align-items: center;
  }

  .popup-contents {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .popup-half {
    flex: 1;
  }
  
  .toggle-view-btn {
    margin-top: 20px;
    padding: 10px 20px;
    cursor: pointer;
  }
}

.popup-container {
  display: flex;
  flex-direction: column;
}


@media (max-width: 1000px) {
  .popup-contents {
    align-items: center;
  }

  .popup-half {
    display: none;
    width: 60%;
  }

  .popup-half:first-child {
    display: block;
  }

  .popup-container {
    width: 100%;
  }

  .close-btn {
    top: 0;
    color: #5d54e7;
  }
}

@media (max-width: 480px) {
  .logo.hed {
    margin-left: 20px;
  }
}

.custom-holder::placeholder {
  color: black;  /* Цвет плейсхолдера */
}
