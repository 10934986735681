.designer-section {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    background-image: url("../../public/image/backdesign.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    text-align: center;
    color: white;
  }
  
  .small-text {
    font-size: 30px;
    font-weight: 400;
    margin-top: 20px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
  }
  
  .large-text {
    font-size: 120px;
    font-weight: bold;
    margin-top: 10px;
    z-index: 2;
    font-family: 'NauryzRedkeds', sans-serif;
  }

  .large-text h2 {
    font-family: 'NauryzRedkeds', sans-serif;
  }
  
  .image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Чтобы перекрывать текст */
  }
  
  .center-image {
    border-radius: 50%; /* Опционально */
  }

  .center-image img {
    width: 90%;
  }
  
  .large-text-overlay {
    font-size: 100px;
    font-weight: bold;
    position: absolute;
    top: 70%;
    right: 5%;
    z-index: 3; /* Над картинкой */
    text-align: right;
    font-family: 'NauryzRedkeds', sans-serif;

    /* Для обводки текста */
    color: transparent;
    -webkit-text-stroke: 4px #ffffff; /* Цвет и толщина обводки */
    text-stroke: 4px #ffffff; /* Для других браузеров */
}

  
  .textn {
    text-align: start;
    padding-left: 5%;
    padding-top: 1%;
  }


  /* Стили для иконок социальных сетей */
  .social-icon {
    width: 40px; /* Размер иконки */
    height: 40px;
    transition: transform 0.3s ease, filter 0.3s ease;
    filter: grayscale(0%); /* Черно-белая иконка в стандартном состоянии */
  }

  .social-icon:hover {
    transform: scale(1.2); /* Увеличиваем размер иконки при наведении */
    filter: grayscale(100%); /* Полностью цветная иконка */
  }

  /* Стили для контейнера социальных сетей */
  .social-media {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center; /* Центрируем иконки */
  }



  #star1 {
    animation: rotate 7s linear infinite;
  }

  #star2 {
    animation: rotate 9s linear infinite;
  }

  /* Определение анимации */
@keyframes rotate {
  0% {
    transform: rotate(0deg); /* Начинаем с 0 градусов */
  }
  100% {
    transform: rotate(360deg); /* Вращаем до 360 градусов */
  }
}

  /* Стили для иконок социальных сетей */
  .social-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  
  .zmei1 {
    position: absolute;
    top: 30%;
    right: 5%;
  }

  .zmei2 {
    position: absolute;
    bottom: 5%;
    left: 5%;
  }

  .star {
    position: absolute;
    top: 10%;
    right: 5%;
  }

  /* Основная секция */
.skills-section {
  background-color: #00a300; /* Зеленый фон */
  color: white;
  text-align: center;
  padding: 50px 10%;
  font-family: 'NauryzRedkeds', sans-serif;
}

.section-title {
  font-size: 75px;
  margin-bottom: 10px;
  font-family: 'NauryzRedkeds', sans-serif;
}

.section-subtitle {
  font-size: 20px;
  margin-bottom: 40px;
}

/* Карточки навыков */
.skills-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.skill-card {
  border: 2px solid rgb(0, 0, 0);
  padding: 20px;
  min-width: 30%;
  position: relative;
}

.skill-name {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
  color: black;
  font-weight: bold;
  font-family: 'Jost', sans-serif;
}

/* Контейнер для прогресс-бара */
.progress-bar {
  position: relative;
  width: 100%;
  height: 4px; /* Толщина линии прогресса */
  background-color: #ffffff; /* Цвет фона */
  overflow: visible; /* Позволяем контенту выходить за границы */
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Заполняемая часть прогресс-бара */
.progress-bar-fill {
  position: relative;
  height: 100%; /* Совпадает с высотой контейнера */
  background-color: #000000; /* Цвет заполнения */
  width: 0; /* Начальная ширина 0%, будет анимироваться */
  transition: width 1s ease-in-out; /* Плавная анимация */
  display: flex;
  align-items: center;
}

/* Иконка прогресса */
.progress-icon {
  position: absolute; /* Позиционируем иконку отдельно */
  width: 24px; /* Ширина иконки */
  height: 24px; /* Высота иконки */
  top: 50%; /* Центруем по вертикали */
  right: -12px; /* Смещаем иконку за границу прогресс-бара */
  transform: translateY(-50%); /* Учитываем вертикальное смещение */
  z-index: 1; /* Поверх других элементов */
  pointer-events: none; /* Исключаем из событий мыши */
}

.progress-bar-fill {
  transition: width 1s ease-in-out;
  -webkit-transition: width 1s ease-in-out; /* Для Safari */
}

.progress-icon {
  transition: transform 1s ease-in-out;
  -webkit-transition: transform 1s ease-in-out; /* Для Safari */
}


.skill-percentage {
  font-size: 18px;
}

.nemperc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iam {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Общий стиль секции */
.services-section {
  background-color: #000;
  color: #fff;
  padding: 40px 0 5% 10%;
  display: flex;
  flex-direction: column;
  gap: 40px; 
}

/* Каждый блок услуги */
.service-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.service-number {
  font-size: 40px;
  color: #fff;
  font-family: 'Jost', sans-serif;
  font-weight: 300;
}

.service-title {
  font-size: 64px;
  font-family: 'NauryzRedkeds', sans-serif;
  line-height: 1.2;
}

.service-price {
  font-size: 40px;
  color: #ffffff;
  padding: 5px 3px;
  border: 1px solid #00ff00;
  border-radius: 5px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
}

/* Звезда-иконка */
.star-icon {
  position: absolute;
  left: 60%;
  top: 35%;
  transform: translateY(-50%);
  width: 7%;
}

.star-icon2 {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translateY(25%);
  width: 10%;
}

/* Линия под услугой */
.service-line {
  width: 100%;
  height: 2px;
  background-color: #00ff00; /* Зеленая линия */
  margin-top: 10px;
}

.left-line {
  width: 38%;
  margin-left: -12%;
}

.starline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -4%;
  margin-top: -4%;
}

.right-line.lines{
  width: 30%;
  padding-left: 3%;
}

.portfolio-section {
  padding: 20px;
  background-color: #000000;
}

.section-title {
  text-align: center;
  font-size: 64px;
  margin-bottom: 20px;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
}

.project-card {
  background-color: #30B100;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
}

.project-details {
  padding: 10px;
}

.project-title {
  font-size: 1.2rem;
  margin: 10px 0;
  font-weight: bold;
}

.project-description,
.project-tools {
  font-size: 0.9rem;
  margin: 5px 0;
}


/* Стиль для круга с изображением инструмента */
.tools-circles {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tool-circle img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

/* Для отображения кружков в popup */
.tools label {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.tool-icon {
  width: 30px;
  height: 30px;
  border-radius: 20%;
  object-fit: cover;
  margin-right: 10px;
}

.designer-portfolio-section {
  padding: 5% 8% 5% 8%;
  background-color: #000000;
}

.designer-portfolio-title {
  font-size: 64px;
  font-family: 'NauryzRedkeds', sans-serif;
  font-weight: bold;
  text-align: right;
}

.designer-portfolio-add-button {
  font-size: 28px;
  color: white;
  background-color: #30b100;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  cursor: pointer;
}

.designer-portfolio-filters {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 3%;
  margin-top: 3%;
}

.designer-portfolio-category-btn {
  padding: 10px 20px;
  border: none;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out; /* Плавные изменения */
  border: 1px solid #00ff00;
  position: relative;
  overflow: hidden;
}

.designer-portfolio-category-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(0, 255, 0, 0.1), transparent);
  transform: skewX(-30deg);
  transition: all 0.3s ease-in-out;
}

.designer-portfolio-category-btn:hover::before {
  left: 100%; /* Эффект проскальзывания света */
}

.designer-portfolio-category-btn:hover {
  background-color: #00ff00; /* Изменение цвета фона */
  color: #000000; /* Изменение цвета текста */
  transform: scale(1.05); /* Увеличение кнопки */
  box-shadow: 0 4px 15px rgba(0, 255, 0, 0.5); /* Добавление свечения */
}

.designer-portfolio-category-btn-active {
  background-color: #00ff00;
  color: #000000;
  box-shadow: 0 4px 15px rgba(0, 255, 0, 0.8); /* Более выраженное свечение для активной кнопки */
}

.designer-portfolio-category-btn-active:hover {
  transform: scale(1.1); /* Чуть сильнее увеличиваем активную кнопку на hover */
}


.designer-portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.designer-portfolio-item {
  background-color: #30B100;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.designer-portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.designer-portfolio-item-image {
  /* width: 100%;
  height: 240px;
  object-fit: cover; */
  max-width: 100%;
  max-height: 240px; /* Ограничение по высоте */
  height: auto;
  object-fit: cover;
}

.designer-portfolio-item-title {
  font-size: 24px;
  font-weight: bold;
  margin: 10px;
}

.designer-portfolio-item-description {
  font-size: 14px;
  margin: 10px;
  color: #ffffff;
  padding-top: 10px;
}

.designer-portfolio-item-tools {
  padding: 10px;
}

.designer-portfolio-item-tools h5{
  font-size: 12px;
  color: #000;
}

.tools-circles {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.tool-circle img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.bestwork {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myportf {
  text-align: right;
  font-size: 20px;
}

.popup2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffffe3;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
}

.popup-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.popup-form input[type="text"],
.popup-form input[type="file"] {
  padding: 10px;
  border: 1px solid #30B100;
  border-radius: 5px;
  font-size: 16px;
  color: #000000;
  background-color: #FFFFFF;
}

.popup-form button {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #30B100;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-form button:hover {
  background-color: #28a000;
}

.categories label,
.tools label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #000000;
}

.tool-icon {
  max-width: 100%;
  max-height: 100%;
}

.popup2 button {
  background-color: #30B100;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup2 button:hover {
  background-color: #28a000;
}

/* Затемнение фона */
.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Анимация закрытия попапа */
.popup-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.popup-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.popup-exit {
  opacity: 1;
  transform: translateY(0);
}
.popup-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

/* Плавное появление нового проекта */
.new-project-enter {
  opacity: 0;
  transform: scale(0.9);
}
.new-project-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.best {
  width: 45%;
}

@media (max-width: 1400px) {
  .large-text-overlay {
    font-size: 80px;
  }
  
  .large-text {
    font-size: 80px;
  }
}

@media (max-width: 1300px) {
  .star-icon {
    left: 68%;
    top: 68%;
  }
}

@media (max-width: 1000px) {
  .large-text-overlay {

    font-size: 60px;
    -webkit-text-stroke: 2px #ffffff;
  }
  
  .large-text {
    font-size: 60px;
  }

  .small-text {
    font-size: 20px;
  }

  .center-image {
    width: 100%;
  }

  .image-wrapper {
    width: 66%;
  }

}


@media (max-width: 768px) {

  .designer-section {
    height: 600px;
  }

  .center-image {
    width: 100%;
  }

  .large-text {
    font-size: 34px;
  }

  .small-text {
    font-size: 18px;
  }

  .textn {
    padding-top: 8%;
  }

  .large-text-overlay {
    font-size: 45px;
    top: 60%;
    -webkit-text-stroke: 2px #ffffff;
  }

  .star {
    top: 70%;
    right: 70%;
  }

  .social-media {
    position: absolute;
    top: 90%;
    right: 40%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    gap: 22px;
  }
  
  .zmei2 {
    bottom: 11%;
    left: 40%;
  }
  
  .zmei1 {
    top: 4%;
    right: 56%;
  }

  .skills-container {
    flex-direction: column;
  }

  .iamstar {
    display: none;
  }

  .section-title {
    text-align: left;
    font-size: 60px;
  }

  .section-subtitle {
    text-align: right;
  }

  .service-title {
    font-size: 50px;
  }

  .right-line {
    width: 10%;
  }

  .right-line.lines {
    width: 48%;
  }

  .star-icon {
    display: none;
  }

  .bestwork {
    flex-direction: column-reverse;
  }

  .best {
    width: 100%;
  }

  .designer-portfolio-title {
    text-align: center;
    font-size: 55px;
  }

  .designer-portfolio-filters {
    flex-wrap: wrap;
  }

  .designer-portfolio-grid {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 15px;
    padding: 10px;
    margin-top: 5%;
    margin: 0px -10%;
    margin-left: 2px;
  }

  .designer-portfolio-item {
    flex: 0 0 auto;
    scroll-snap-align: center;
    width: 80%; /* Можно настроить ширину карточек */
    max-width: 300px;
  }

  .designer-portfolio-item img {
    border-radius: 8px;
  }

  .designer-portfolio-section {
    padding-top: 20%;
  }

}


@media (max-width: 520px) {
  .section-title {
    font-size: 55px;
  }

  .service-title {
    font-size: 35px;
  }

  .service-number {
    font-size: 25px;
  }

  .designer-portfolio-title {
    font-size: 40px;
  }

  .myportf {
    font-size: 15px;
  }

  .large-text-overlay {
    font-size: 36px;;
  }
}

@media (max-width: 420px) {

  .center-image {
    width: 100%;
  }

  .large-text {
    font-size: 26px;
  }

  .small-text {
    font-size: 14px;
  }

  .textn {
    padding-top: 22%;
  }

  .large-text-overlay {
    font-size: 26px;
    top: 63%;
    -webkit-text-stroke: 1.5px #ffffff;
  }

  .section-title {
    text-align: left;
    font-size: 42px;
  }

  .service-title {
    font-size: 25px;
  }

  .designer-portfolio-title {
    font-size: 32px;
  }

  .social-media {
    right: 30%;
}
}

@media (max-width: 340px) {
  
  .section-title {
    font-size: 36px;
  }

  .large-text-overlay {
    font-size: 22px;
  }

  .large-text {
    font-size: 22px;
  }

  .small-text {
    font-size: 12px;
  }

  .textn {
    padding-top: 40%;
  }

  .zmei1 {
    top: 11%;
    right: 28%;
}

}