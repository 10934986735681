.admin-dashboard-container {
    max-width: 600px;
    margin: 100px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
    text-align: center;
  }
  
  .admin-dashboard-title {
    font-size: 1.8rem;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .admin-dashboard-message {
    font-size: 1.2rem;
    color: #495057;
    line-height: 1.6;
  }
  
  .admin-dashboard-projects {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #007bff;
  }
  