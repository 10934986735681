/* skil.css */
.content-skil {
  background-color: #1c1d25; /* Цвет фона */
  color: #fff;
  font-family: monospace;
}

.skills-categories {
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle, #130e24 50%, #00101f 100%);
}

.category-block {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #555; /* Верхняя линия по всей ширине */
  border-bottom: 1px solid #555; /* Нижняя линия по всей ширине */
  padding: 50px 10%;
  position: relative;
  overflow: hidden;
}

.category-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.category-title {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.category-number {
  font-size: 16px;
  color: #888;
  margin-bottom: 5px;
}

.category-title h2 {
  font-size: 60px; /* Размер главного пункта */
  margin: 0;
}

.category-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  width: 35%;
}

.category-details p {
  font-size: 16px;
  color: #ccc;
  margin: 0;
}

.category-link {
  font-size: 16px;
  color: #5d54e7;
  animation: glow 2s infinite alternate;
  text-decoration: none;
  z-index: 10;
}

.category-link:hover {
  color: #fff;
}

.tilda-category {
  position: relative;
  overflow: hidden;
}

.tilda-category:hover canvas {
  transform: scale(1.1);
  transition: transform 2s ease-in-out;
}

.category-block.first-category {
  position: relative;
  overflow: hidden;
  background-image: none; /* Фон отсутствует по умолчанию */
  transition: background 0.5s ease-in-out; /* Плавное появление фона */
}

.category-block.first-category:hover {
  background-image: url('../../public/image/backimage.png'); /* Укажите корректный путь */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.category-title {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.category-price {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 12px;
  font-weight: bold;
  color: #333;
  font-size: 20px;
}

.service-icons {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle img {
  width: 60%;
  height: 60%;
}

@media (max-width: 600px) {
  .category-title h2 {
    font-size: 45px;
  }

  .category-details {
    width: 41%;
  }
}


@media (max-width: 480px) {
  .category-title h2 {
    font-size: 38px;
  }

  .category-details {
    width: 41%;
  }

  .category-details p {
    font-size: 14px;
  }

  .icon-circle {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 400px) {
  .category-title h2 {
    font-size: 30px;
  }

  .category-details p {
    font-size: 12px;
  }

  .icon-circle {
    width: 25px;
    height: 25px;
  }

  .category-price {
    font-size: 15px;
  }
}