/* @font-face {
  font-family: 'NauryzRedkeds';
  src: url('../assets/fonts/NAURYZREDKEDS.woff2') format('woff2'),
       url('../assets/fonts/NAURYZREDKEDS.woff') format('woff'),
       url('../assets/fonts/NAURYZREDKEDS.ttf') format('truetype'),
       url('../assets/fonts/NAURYZREDKEDS.svg#NauryzRedkeds') format('svg');
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "NauryzRedKeds";
  src: url("https://db.onlinewebfonts.com/t/1efc9dc7414e979667bdca47989dff12.eot");
  src: url("https://db.onlinewebfonts.com/t/1efc9dc7414e979667bdca47989dff12.eot?#iefix") format("embedded-opentype"),
       url("https://db.onlinewebfonts.com/t/1efc9dc7414e979667bdca47989dff12.woff2") format("woff2"),
       url("https://db.onlinewebfonts.com/t/1efc9dc7414e979667bdca47989dff12.woff") format("woff"),
       url("https://db.onlinewebfonts.com/t/1efc9dc7414e979667bdca47989dff12.ttf") format("truetype"),
       url("https://db.onlinewebfonts.com/t/1efc9dc7414e979667bdca47989dff12.svg#NauryzRedKeds") format("svg");
}

@font-face {
  font-family: "Jost";
  src: url("https://db.onlinewebfonts.com/t/25ad17996805495ee069542169e4504f.eot");
  src: url("https://db.onlinewebfonts.com/t/25ad17996805495ee069542169e4504f.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/25ad17996805495ee069542169e4504f.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/25ad17996805495ee069542169e4504f.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/25ad17996805495ee069542169e4504f.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/25ad17996805495ee069542169e4504f.svg#Jost")format("svg");
}


/* @font-face {
  font-family: 'Jost';
  src: url('../assets/fonts/Jost-Light.woff2') format('woff2'),
       url('../assets/fonts/Jost-Light.woff') format('woff'),
       url('../assets/fonts/Jost-Light.ttf') format('truetype'),
       url('../assets/fonts/Jost-Light.svg#Jost') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../assets/fonts/Jost-ExtraLight.woff2') format('woff2'),
       url('../assets/fonts/Jost-ExtraLight.woff') format('woff'),
       url('../assets/fonts/Jost-ExtraLight.ttf') format('truetype'),
       url('../assets/fonts/Jost-ExtraLight.svg#Jost') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../assets/fonts/Jost-Bold.woff2') format('woff2'),
       url('../assets/fonts/Jost-Bold.woff') format('woff'),
       url('../assets/fonts/Jost-Bold.ttf') format('truetype'),
       url('../assets/fonts/Jost-Bold.svg#Jost') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */
