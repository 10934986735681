/* Общий стиль секции */
.content-section {
    height: auto;
    background: radial-gradient(circle, #130e24 50%, #00101f 100%);
    padding: 5% 0 0% 0%;
}

.content-section h2 {
    font-size: 60px;
    margin-bottom: 10px;
    text-align: center;
}

/* Категории (кнопки) */
.portfolio-categories {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.category-btn {
    padding: 10px 20px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 10px;
    margin-right: 10px;
}

/* Состояние активной категории */
.category-btn.active {
    background: linear-gradient(45deg, #4D46B8, #5D54E7); /* Градиент фиолетового фона для активной категории */
    color: white;
    border: 2px solid #ffffff00; /* Фиолетовая граница */
}

.category-btn:hover {
    background: linear-gradient(45deg, #4D46B8, #5D54E7);
    color: white;
}

/* Стили для карточек портфолио */
.portfolio-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.portfolio-card {
    position: relative;
    background: linear-gradient(180deg, rgba(200, 200, 200, 0.1) 0%, rgba(240, 240, 240, 0.1) 60%);
    backdrop-filter: blur(70px);
    color: white;
    padding: 40px 20px;
    border-radius: 8px;
    margin: 10px 10px 40px;
    width: 250px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, background 0.3s ease;
}

/* Анимация при наведении */
.portfolio-card:hover {
    transform: translateY(-10px); /* Карточка поднимается при наведении */
}

/* Стиль активной карточки с фиолетовым градиентом */
.portfolio-card.active {
    background: linear-gradient(45deg, #4D46B8, #5D54E7); /* Градиент */
    color: white;
}

/* Внутренние элементы карточки */
.portfolio-card img {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 35%;
    border-radius: 15%;
    border: 3px solid white;
    object-fit: cover;
}

.portfolio-card h4 {
    margin-top: 60px; /* Отступ для изображения */
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.portfolio-card p {
    margin-bottom: 20px;
    font-size: 14px;
}

.portfolio-card a {
    display: inline-block;
    padding: 10px 20px;
    background-color: white;
    color: #202836;
    border-radius: 20px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Анимация при наведении на кнопку */
.portfolio-card a:hover {
    background-color: #202836;
    color: white;
}

/* Мобильные стили */
@media (max-width: 768px) {

    .category-btn {
        margin-bottom: 10px;
    }

    .portfolio-content {
        justify-content: flex-start;
    }

    .portfolio-card {
        width: 65%;
        margin-bottom: 0px;
    }

    .portfolio-card h4{
      margin-top: 110px;
  }
}

@media (max-width: 480px) {
  .content-section h2 {
    font-size: 50px;
  }
}

/* Плюсик для открытия попапа */
.portfolio-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1%;
  }
  
  .add-card-button {
    font-size: 40px;
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    transition: color 0.3s ease;
  }
  
  .add-card-button:hover {
    color: #5D54E7;
  }
  
  /* Стиль попапа */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #1c1d25;
  padding: 30px;
  border-radius: 10px;
  color: white;
  max-width: 500px;
  width: 100%;
}

.popup-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup-content .category-selection {
  margin-top: 20px;
}

.popup-content .category-selection h4 {
  margin-bottom: 10px;
}

.popup-content .category-selection label {
  display: block;
  margin-bottom: 5px;
}

.close-popup {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #5D54E7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup:hover {
  background-color: #4D46B8;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Делаем поп-ап над всеми элементами */
}

/* Адаптация для мобильных устройств */
@media (max-width: 950px) {
  .portfolio-categories {
    flex-wrap: wrap;
    justify-content: center;
    
  }

  .portfolio-card:hover {
    transform: none; /* Убираем поднятие */
  }
}

@media (max-width: 768px) {
  .portfolio-content {
    flex-wrap: nowrap; /* Отключаем перенос строк */
    overflow-x: auto; /* Добавляем горизонтальную прокрутку */
    scroll-snap-type: x mandatory; /* Привязка к ближайшему элементу */
    gap: 8px; /* Уменьшаем расстояние между карточками */
  }

  .portfolio-card img {
    top: -9px;
  }

  .portfolio-card {
    flex: 0 0 80%; /* Каждая карточка занимает 80% ширины контейнера */
    max-width: 44%;
    scroll-snap-align: center; /* Карточки центрируются при прокрутке */
  }

  .portfolio-content::-webkit-scrollbar {
    display: none; /* Скрываем полосу прокрутки для эстетики */
 
  }
}

@media (max-width: 520px) {
  .portfolio-card {
    max-width: 60%;
  }
}

@media (max-width: 380px) {
  .portfolio-card {
    max-width: 70%;
  }
}