html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: monospace;
}
.home {
    position: relative;
    height: 90vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-heading {
    z-index: 9;
    position: relative;
    user-select: none;
  }
  
  .background-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: radial-gradient(circle, #130e24 50%, #00101f 100%);
  }
  
  .code-tag {
    position: absolute;
    font-family: monospace;
    color: rgba(255, 255, 255, 0.5);
    animation: float 20s infinite ease-in-out;
  }
  
  @keyframes float {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(10px, -10px);
    }
    100% {
      transform: translate(0, 0);
  }
}
  
  .highlighted-elements {
    z-index: 9;
  }
  
  .highlighted-code {
    position: absolute;
    font-family: monospace;
    font-size: 2rem;
    color: #5d54e7;
    padding: 0 5px;
    animation: glow 2s infinite alternate;
    display: inline-block;
    user-select: none;
  }
  
  .highlighted-code::before {
    content: "<h2>";
    position: absolute;
    left: -40px;
    top: 20%;
    color: gray;
    font-size: 1.2rem;
    opacity: 0.7;
  }
  
  .highlighted-code::after {
    content: "</h2>";
    position: absolute;
    right: -50px;
    top: 20%;
    color: gray;
    font-size: 1.2rem;
    opacity: 0.7;
  }

  .highlighted-code.one {
    top: 50%;
    left: 10%;
  }

  .highlighted-code.two {
    top: 30%;
    right: 10%;
  }

  .highlighted-code.three {
    bottom: 12%;
    left: 60%;
  }
  
  @keyframes glow {
    from {
        text-shadow: 0 0 10px #5d54e7, 0 0 20px #5d54e752;
    }
    to {
      text-shadow: 0 0 20px #5d54e7, 0 0 30px #5d54e752;
    }
  }
  

img {
    -webkit-user-drag: none; /* Запрещает перетаскивание в Chrome/Safari */
    -khtml-user-drag: none; /* Запрещает перетаскивание в Safari 3 */
    -moz-user-drag: none; /* Запрещает перетаскивание в Firefox */
    -o-user-drag: none; /* Запрещает перетаскивание в Opera */
    user-drag: none; /* Запрещает перетаскивание в общем случае */
    user-select: none; /* Запрещает выделение текста */
}

.home-content {
    position: relative; /* Для позиционирования элементов внутри */
    text-align: left; /* Текст выровнен по левому краю */
    padding-right: 10px;
}

.cent {
    display: flex;  
    align-items: flex-start;   
    width: 500px; 
}

.mult {
    font-size: 32px;
    margin-top: 3%;
    margin-left: 4%;
}

.mult span {
    color: #5d54e7;
}

.parent-element {
    -webkit-transform: translate3d(0, 0, 0);
  }
  

/* Анимация и стили для заголовков */
.custom-heading {
    font-size: 140px;
    font-weight: 700;
    margin: -3px 0;
    opacity: 0;
    animation: slideRight 1s ease forwards;
    animation-delay: 1s;
}


/* Линия 1 заголовка */
.custom-heading .line1 {
    display: block;
    position: relative;
    padding-left: 150px;
}

/* Линия 2 заголовка */
.custom-heading .line2 {
    display: block;
    position: relative;
}

/* Стили для h3 и multiple-text */
h3 {
    display: inline-block;
    position: absolute; /* Позиционирование относительно родителя (home-content) */
    top: 100%; /* Расположить ниже line2 */
    left: 100%; /* Справа от line2 */
    margin-left: 20px; /* Отступ для точного выравнивания */
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap; /* Предотвращает перенос текста на новую строку */
}

.multiple-text {
    color: #000; /* или другой подходящий цвет */
    white-space: nowrap; /* Запрещаем перенос текста */
  }
  

/* Мобильные стили */
@media (max-width: 768px) {
    .custom-heading {
        font-size: 100px;
    }

    .mult {
        font-size: 25px;
    }

    .custom-heading .line1,
    .custom-heading .line2 {
        padding-left: 20px;
    }

    h3 {
        left: 80%; /* Регулируем для мобильных устройств */
        margin-left: 10px;
        font-size: 18px;
    }
}

@media (max-width: 540px) {
    .custom-heading {
        font-size: 83px;
    }

    .mult {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .custom-heading {
        font-size: 65px;
    }

    .mult {
        font-size: 15px;
    }

    .cent {
        width: 300px;
    }

    .custom-heading .line1, .custom-heading .line2 {
        padding-left: 0px;
    }
}


@media (max-width: 360px) {
    .custom-heading {
        font-size: 65px;
    }

    .mult {
        font-size: 15px;
    }

    .cent {
        width: 300px;
    }
}

/* Стили для h3 с multiple-text */
h3 {
    margin-top: -20px; /* Отрегулируйте этот отступ для точного выравнивания */
    font-size: 24px; /* Размер текста для h3 */
    font-weight: 500;
    text-align: left;
}

.logo img{
    cursor: pointer;
}

body {
    background: radial-gradient(circle, #130e24 50%, #00101f 100%);
    color: #fff;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 10%;
    display: flex;
    background: radial-gradient(circle, #130e24 50%, #00101f 100%);
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.logo {
    font-size: 25px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    cursor: default;
    opacity: 0;
    animation: slideRight 1s ease forwards;
}

.logo img {
    width: 180px;
}

.navbar a {
    display: inline-block;
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    margin-left: 35px;
    transition: .3s;
    opacity: 0;
    animation: slideTop .5s ease forwards;
    animation-delay: calc(.2s * var(--i));
    line-height: 1.8;
}

.navbar a:hover {
    color: #5d54e7;
}

.home {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10% 0;
}


.home-content h3:nth-of-type(2) {
    margin-bottom: 30px;
    animation: slideTop 1s ease forwards;
    animation-delay: .7s;
}

.home-content h3 span {
    color: #5d54e7;
}

.home-content p {
    font-size: 16px;
    opacity: 0;
    font-family: 'Poppins', sans-serif;
    animation: slideLeft 1s ease forwards;
    animation-delay: 1s ;
}


.btn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 2px solid #5d54e7;
    border-radius: 50%;
    font-size: 20px;
    text-decoration: none;
    margin: 30px 15px 30px 0;
    transition: .5s ease;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: calc(.2s * var(--i));
    border: none;
    margin-left: 30px;
}

.btn:hover {
    background: #5d54e7;
    color: #1c1d25;
    box-shadow: 0 0 20px #5d54e7;
}

.btn {
    display: inline-block;
    padding: 9px 15px;
    background: #5d54e7;
    border-radius: 5px;
    box-shadow: 0 0 10px #5d54e7;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 600;
    transition: .5s ease;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    animation-delay: 2s;
    cursor: pointer;
}

.btn:hover {
    background: #1c1d25;
    color: #5d54e7;
    box-shadow: 0 0 20px #5d54e7;
}

.Burger {
    display: none;
    position: absolute;
    z-index: 8;
    align-items: center;
    justify-content: flex-end;
    width: 30px;
    height: 18px;
    top: 0;
    right: 0;
    margin-top: 45px;
    margin-right: 40px;
    cursor: pointer;
}

.Burger span{
    height: 3px;
    width: 100%;
    border-radius: 40px;
    transform: scale(1);
    background-color: #ffffff;
}

.Burger::before, .Burger::after{
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 40px;
    background-color: #5d54e7;
    transition: all 0.3s ease 0s;
}

.Burger::before {top: 0}
.Burger::after {bottom: 0}

.Burger.active span{transform: scale(0);}

.Burger.active::before {
    background-color: #ffffff;
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
}

.Burger.active::after {
    background-color: #ffffff;
    bottom: 50%;
    transform: rotate(45deg) translate(0, -50%);
}

@keyframes slideRight {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes floatImage {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-24px);
    }

    100% {
        transform: translateY(0);
    }
}

@media (max-width: 1600px) {

    .home-imgs {
        margin-right: -40px;
    }

    .home-img img{
        width: 450px;
    }

    .back-img img{
        width: 450px;
    }
}


@media (min-width: 1200px) {
    .navbar{
        max-width: 1200px;
    }
}

@media (max-width: 1200px) {


    .home-imgs {
        margin-right: -140px;
    }

    .home-img img{
        width: 400px;
    }

    .back-img img{
        width: 400px;
    }
}

@media (max-width: 992px) {

    .home-imgs {
        width: 60px;
        margin-right: 220px;
    }

    .home-img img{
        width: 350px;
    }

    .back-img img{
        width: 350px;
    }

    .navbar a {
        font-size: 15px;
    }

    .btn {
        font-size: 14px;
    }
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    position: relative;
    z-index: 10;
  }
  
  .logo img {
    width: 180px;
  }
  
  .burger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
  }
  
  .burger span {
    width: 25px;
    height: 3px;
    background-color: #000;
    border-radius: 2px;
  }

  /* Полноэкранное меню */
.full-screen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1d25;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9;
  }
  
  .full-screen-menu.visible {
    transform: translateY(0);
  }
  
  .full-screen-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .full-screen-menu li {
    margin: 20px 0;
  }
  
  .full-screen-menu a {
    text-decoration: none;
    font-size: 24px;
    color: #ffffff;
    transition: color 0.2s;
  }
  
  .full-screen-menu a:hover {
    color: #007BFF;
  }
  
  .close-menu-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 60px;
    cursor: pointer;
    color: #ffffff;
  }

@media (max-width: 900px) {

    .home-imgs {
        display: none;
    }

    .logo img {
        width: 180px;
    }

    .navbar {
        display: none;
      }

    .Burger{
        display: flex;
    }
    
    
    .header.open .navbar{
        transform: translateX(-120%);
    }

    .header{
        padding: 30px
    }

}

@media (max-width: 480px) {

    .header{
        padding: 30px 2%
    }
    .home-imgs {
        display: none;
    }

    .btn{
        font-size: 14px;
    }
}

@media (max-width: 320px) {

    .home-content h3 {
        font-size: 12px;
    }

    .logo img {
        width: 150px;
    }

    .Burger{
        display: flex;
    }
    
    .navbar {
        position: absolute;
        text-align: end;
        display: block;
        right: -250px;
        color: #ffffff;
        top: 0;
        width: 200px;
        height: 300px;
        background-color: #30A4AC;
        padding-top: 100px; padding-right: 60px;
        transition: transform .5s;
    }
    
    .header.open .navbar{
        transform: translateX(-100%);
    }

    .header{
        padding: 30px
    }
    .header{
        padding: 30px 2%
    }
    .home-img {
        display: none;
    }
    .home-content h1{
        margin: 10px 0;
    }
    .btn{
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .highlighted-code {
      font-size: 1.5rem; /* Уменьшенный размер текста */
    }
  
    .highlighted-code.one {
        top: 10%;
        left: 8%;
    }
  
    .highlighted-code.two {
        top: 27%;
        right: 8%;
    }
  
    .highlighted-code.three {
      bottom: 8%;
      left: 50%;
      transform: translateX(-50%); /* Центровка */
    }
  }

  @media (max-width: 480px) {
    .highlighted-code {
      font-size: 1.5rem; /* Уменьшенный размер текста */
    }
  
    .highlighted-code.one {
        top: 8%;
        left: 15%;
    }
  
    .highlighted-code.two {
        top: 20%;
        right: 16%;
    }
  
    .highlighted-code.three {
      bottom: 10%;
      left: 32%;
      transform: translateX(-50%); /* Центровка */
    }
  }

  @media (max-width: 360px) {
    .highlighted-code {
      font-size: 1.5rem; /* Уменьшенный размер текста */
    }
  
    .highlighted-code.one {
        top: 25%;
        left: 15%;
    }
  
    .highlighted-code.two {
        top: 37%;
        right: 8%;
    }
  
    .highlighted-code.three {
      bottom: 8%;
      left: 50%;
      transform: translateX(-50%); /* Центровка */
    }
  }