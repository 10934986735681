/* Container for the entire page */
.designer-container {
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
}

/* Header section for designer's main image and title */
.designer-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

/* Styling for the main designer image */
.designer-main-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.designer-title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px;
    text-align: center;
}

.designer-title h2{
   font-family: 'NauryzRedkeds'
}

/* Description styling */
.designer-description {
    font-size: 16px;
    line-height: 1.6;
    color: #ffffff;
    margin: 20px 0;
    text-align: center;
}

/* Gallery styling */
.designer-gallery {
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Admin Panel styling */
.designer-admin-panel {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.designer-upload-input {
    display: block;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #000000;
}

.designer-upload-button {
    padding: 10px 20px;
    background-color: #30B100;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.designer-upload-button:hover {
    background-color: #28A100;
}

/* Navigation buttons */
.designer-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 5%;
}

.designer-nav-button {
    padding: 10px 20px;
    background-color: #28a000;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.designer-nav-button:hover {
    background-color: #444444;
}

.designer-prev {
    margin-right: 10px;
}

.designer-next {
    margin-left: 10px;
}

/* For MatrixText component */
.matrix-text {
    display: inline-block;
    font-family: 'NauryzRedkeds';
    color: #30B100;
}

.back-button-container {
    margin: 20px 0;
    text-align: center;
}

.back-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #30b100;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #45a049;
}

.designer-delete-button {
    margin-top: 5px;
    padding: 5px 10px;
    background-color: #ff0000;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .designer-delete-button:hover {
    background-color: #cc0000;
  }
  
  .admin-image-delete-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.admin-image-delete-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-gallery-image {
    max-width: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.delete-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
}

.delete-button:hover {
    background-color: #c0392b;
}
