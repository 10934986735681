.footer {
  background: radial-gradient(circle, #130e24 50%, #00101f 100%);
    padding: 40px 0;
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
  }
  
  .footer-header h2 {
    font-size: 32px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .footer-info .mail a {
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-info .mail a:hover {
    color: #5d54e7;
  }
  
  .social-medias {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-medias a {
    align-items: center;
    animation: slideLeft 1s ease forwards;
    animation-delay: calc(.2s* var(--i));
    background: #0000;
    border: 2px solid #5d54e7;
    border-radius: 50%;
    color: #5d54e7;
    display: inline-flex;
    font-size: 28px;
    height: 60px;
    justify-content: center;
    opacity: 0;
    text-decoration: none;
    transition: .5s ease;
    width: 60px;
  }

  .social-medias.neich a{
    border: 2px solid #30B100;
    color: #30B100;
  } 

  .social-medias.neich a:hover {
    background-color: #30B100;
    color: #202836;
  }
  
  .social-medias a:hover {
    background-color: #5d54e7;
    color: #202836;
  }
  
  @media (max-width: 768px) {
    .footer-header h2 {
      font-size: 28px;
    }
  
    .footer-info .mail a {
      font-size: 18px;
    }
  
    .social-medias a {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }

    .logo.footer img{
      margin-left: 0;
    }
  }
  