.contact-section {
    background-color: black;
    color: white;
    text-align: center;
    padding: 5%;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-section h1 {
    font-size: 64px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
    font-family: 'NauryzRedkeds';
  }
  
  .container {
    max-width: 80%;
    margin: 0 auto;
    text-align: left;
  }
  
  .form-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .contact-section input,
  .contact-section textarea {
    background-color: black;
    border: 2px solid #00ff00;
    color: white;
    min-height: 50px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    outline: none;
    border-radius: 12px;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
  }

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: white;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: white;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color: white;
}

::placeholder { /* Most modern browsers support this now. */
   color: white;
}
  
  .contact-section textarea {
    resize: none;
    height: 150px;
  }
  
  textarea + .submit-button {
    margin-top: 10px;
    align-self: flex-end;
  }
  
  .submit-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .submit-button {
    background-color: #30B100;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
    transition: 0.3s;
    margin-top: 20px;
    width: 48%;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
  }
  
  .submit-button:hover {
    background-color: #007700;
  }
  
  .decorative-line {
    height: 2px;
    width: 100%;
    background: linear-gradient(90deg, #333333, #333333);
  }
  
  .footerd {
    background: radial-gradient(circle, #000000 50%, #000000 100%);
    padding: 40px 0;
    text-align: center;
    color: #ffffff;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
  }
  
  .lot {
    width: 6%;
  }

  .lot2 {
    margin-left: -7%;
  }

  .lines.lots {
    width: 40%;
  }

  .lines.lots2 {
    width: 46%;
    padding-left: 0%;
  }

  .duoline {
    margin-bottom: 1%;
    margin-right: 5%;
    margin-top: -2%;
  }

  .duoline2 {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .status-message {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .status-message.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  
  .status-message.error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  }
  
  .status-message.warning {
    color: #856404;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
  }
  
  @media (max-width: 768px) {

    .container {
        max-width: 95%;
    }

    .contact-section h1 {
        font-size: 45px;
    }

    .lot{
        display: none;
    }

    .lines.lots {
        width: 100%;
        margin-bottom: 5%;
        
    }
    
    .duoline2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: -19%;
        margin-bottom: 4%;
    }

    .lot2 {
        width: 14%;
    }

    .contact-section input, .contact-section textarea {
        font-size: 12px;
    }
  }

  .custom-placeholder::placeholder {
    color: rgb(255, 255, 255);  /* Цвет плейсхолдера */
  }
  