/* Основной контейнер */
.admin-login-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    background-color: #f8f9fa00;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Заголовок */
  .admin-login-title {
    text-align: center;
    font-size: 1.5rem;
    color: #5d54e7;
    margin-bottom: 20px;
  }
  
  /* Ошибка */
  .admin-login-error {
    color: #dc3545;
    font-size: 0.9rem;
    margin-bottom: 15px;
    text-align: center;
  }
  
  /* Форма */
  .admin-login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Группа полей */
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  /* Метка */
  .form-label {
    font-size: 0.9rem;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  /* Поле ввода */
  .form-input {
    padding: 10px;
    font-size: 1rem;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    border-color: #15ffb1;
  }
  
  /* Кнопка */
  .form-button {
    padding: 10px 20px;
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
    background: radial-gradient(circle, #00ff00 6%, #6c63ff 100%);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  
  .form-button:active {
    background-color: #004085;
  }
  