:root {
    --primary-green: #30B100;
    --black: #000000;
    --white: #FFFFFF;
  }
  
  
  .designer-headerd {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background-color: var(--black);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    flex-direction: row;
  }
  
  .logo img {
    height: 50px;
    transition: transform 0.3s ease;
  }
  
  .logo img:hover {
    transform: scale(1.1);
  }
  
  .navbard {
    display: flex;
    align-items: center;
    gap: 20px;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .navbard a {
    color: var(--white);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .navbard a:hover {
    color: var(--primary-green);
  }
  
  .navbard.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
   .btn.request-btn2 {
    background-color: var(--primary-green);
    color: var(--white);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'JOST';
  }
  
  .christmas-hat {
    position: absolute;
    top: -27px;
    right: -14px;
    transform: rotate(15deg);
    width: 40px;
    height: auto;
    transition: transform 0.3s ease, top 0.3s ease;
    pointer-events: none;
  }
  
  .Burgerd  {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
  }
  
  .Burgerd  span {
    width: 25px;
    height: 3px;
    background-color: var(--black);
    transition: transform 0.3s ease;
  }
  
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .designer-headerd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5%;
    background-color: var(--black);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .logo img {
    height: 50px;
  }
  
  .navbard {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 5%;
  }
  
  .navbard a {
    color: var(--white);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    font-family: 'NauryzRedkeds';
  }
  
  .navbard a:hover {
    color: var(--primary-green);
  }
  
  .navbard.visible {
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    padding: 20px;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
  
  .Burgerd  {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    z-index: 1100;
  }
  
  .Burgerd  span {
    width: 25px;
    height: 3px;
    background-color: var(--primary-green);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .Burgerd .open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .Burgerd .open span:nth-child(2) {
    opacity: 0;
  }
  
  .Burgerd .open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  @media (max-width: 768px) {
    .designer-headerd {

        padding: 2% 5%;

      }

    .navbard {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      transform: translateX(100%);
    }
  
    .Burgerd {
      display: flex;
    }
  }